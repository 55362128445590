<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="562"
		content-class="tw-rounded-29px"
	>
		<v-card class=" tw-px-16 tw-pb-11 tw-pt-16">
			<v-img
				width="256"
				height="256"
				class="tw-mx-auto"
				src="@/assets/images/accent-checked-full.svg"
			/>

			<h1
				class="tw-mt-16 tw-text-center tw-font-pop tw-text-3xl tw-font-semibold tw-text-black tw-leading-normal"
			>
				{{ $t('orderPlaced') }}
			</h1>

			<p
				class="tw-text-center tw-mt-4 tw-font-pop tw-font-normal tw-text-base tw-text-bcb tw-max-w-sm tw-mx-auto tw-pb-0"
			>
				{{ $t('orderPlacedDesc') }}
			</p>

			<v-btn
				block
				depressed
				height="55"
				color="accent"
				class="tw-rounded-lg tw-mt-14"
				@click="$router.push('/')"
			>
				<span
					class="tw-font-pop tw-font-medium tw-text-base xl:tw-text-lg tw-normal-case"
				>
					{{ $t('actions.back') }}
				</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	name: 'DialogOrderPlaced',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		dialog: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
			},
		},
	},
}
</script>
