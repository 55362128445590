<template>
	<v-app>
		<div class="tw-container tw-mx-auto">
			<div class="tw-min-h-screen tw-w-full tw-grid tw-place-items-center">
				<v-card flat>
					<template v-if="isVerifiying">
						<div class="tw-max-w-xs tw-mx-auto tw-py-10 tw-text-center">
							<img
								alt=""
								class="tw-w-full"
								src="/@src/assets/img/undraw_make_it_rain_iwk4.svg"
							/>
							<v-progress-circular
								indeterminate
								color="primary"
								class="tw-mt-8"
								size="96"
								width="6"
							/>
							<div class="tw-text-xl tw-font-medium tw-mt-6 xl:tw-mt-12">
								{{ $t('verifingPayment') }}
							</div>
						</div>
					</template>

					<template v-if="!isVerifiying && isFailed === true">
						<div class="tw-max-w-xs tw-mx-auto tw-py-10 tw-text-center">
							<v-icon size="96" class="tw-text-red-500 tw-inline-block">
								mdi-emoticon-sad-outline
							</v-icon>
							<div class="tw-text-xl tw-mt-8 tw-font-medium">
								{{ $t('paymentFailed') }}
							</div>
							<div
								v-if="type === types.CART"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/orders')"
									>{{ $t('see-unpaid-orders') }}</v-btn
								>
							</div>
							<div
								v-else-if="type === types.ADVERTISEMENT"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/supplier/ads')"
									>{{ $t('see-advertisements') }}</v-btn
								>
							</div>
							<div
								v-else-if="type === types.MARKETING_OFFER"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="
										$router.push(
											'/supplier/offers?tab=marketingOffers'
										)
									"
									>{{ $t('see-marketing-offers') }}</v-btn
								>
							</div>
							<div
								v-else-if="type === types.SUBSCRIPTION"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/packages')"
								>
									See Packages
								</v-btn>
							</div>
						</div>
					</template>

					<template v-else-if="!isVerifiying && isFailed === false">
						<div class="tw-max-w-xs tw-mx-auto tw-py-10 tw-text-center">
							<v-icon
								size="96"
								color="success"
								class="tw-text-primary tw-inline-block"
							>
								mdi-emoticon-happy-outline
							</v-icon>
							<div class="tw-text-xl tw-mt-8 tw-font-medium">
								{{ $t('paymentSuccess') }}
							</div>

							<!-- CART -->
							<div
								v-if="type === types.CART"
								class="tw-justify-center tw-mt-8 tw-space-s-4"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/orders')"
									>{{ $t('see-orders') }}</v-btn
								>
								<v-btn
									large
									depressed
									color="success"
									@click="$router.push('/store')"
									>{{ $t('shop-more') }}</v-btn
								>
							</div>

							<!-- SUBSCRIPTION -->
							<div
								v-else-if="type === types.SUBSCRIPTION"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/subscription')"
								>
									{{ $t('go-to-subscription') }}
								</v-btn>
							</div>

							<!-- ADVERTISEMENT -->
							<div
								v-else-if="type === types.ADVERTISEMENT"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="$router.push('/supplier/ads')"
									>{{ $t('see-advertisements') }}</v-btn
								>
							</div>

							<!-- MARKETING OFFER -->
							<div
								v-else-if="type === types.MARKETING_OFFER"
								class="tw-justify-center tw-mt-8"
							>
								<v-btn
									large
									depressed
									color="primary"
									@click="
										$router.push(
											'/supplier/offers?tab=marketingOffers'
										)
									"
									>{{ $t('see-marketing-offers') }}</v-btn
								>
							</div>
						</div>
					</template>

					<template v-if="!isVerifiying">
						<div
							class="tw-mt-4 tw-font-semibold tw-text-black tw-text-center"
						>
							Redirecting in {{ countDown }}s
						</div>
					</template>
				</v-card>
			</div>
		</div>
		<DialogOrderPlaced v-model="dialogSuccess" />
	</v-app>
</template>

<script>
import api from '../api'
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_ROUTE, PAYTAB_PAYMENT_TYPE } from '../consts'
import DialogOrderPlaced from '../components/dialogs/DialogOrderPlaced.vue'

export default {
	name: 'PaymentCallback',
	components: {
		DialogOrderPlaced,
	},
	data() {
		return {
			type: null,
			isFailed: null,
			isVerifiying: false,
			dialogSuccess: false,

			countDown: 3,
			countDownInterval: null,

			types: PAYTAB_PAYMENT_TYPE,
			returnRoutes: {
				[PAYTAB_PAYMENT_TYPE.CART]: '/store',
				[PAYTAB_PAYMENT_TYPE.SUBSCRIPTION]: '/subscription',
			},
		}
	},
	computed: {
		...mapGetters('orders', ['$currentOrder']),
		...mapGetters('settings', ['$currentPayment']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('packages', ['$subscriptionTransactionRef']),
	},
	async created() {
		// Guard
		const { type, tranRef } = this.$currentPayment
		console.log(DEFAULT_ROUTE)
		// if (!type) {
		// 	return this.$router.replace(DEFAULT_ROUTE[this.$currentUser.role])
		// }
		// if (!tranRef) return this.$router.replace(this.returnRoutes[type])

		// Payment type
		this.type = type

		// Verify payment
		this.isVerifiying = true
		let [err, res] = await api.get(`payment/check/${tranRef}`, { type })
		this.isVerifiying = false

		if (type === PAYTAB_PAYMENT_TYPE.SUBSCRIPTION) {
			await this.fetchProfile()
		}

		// Payment failed
		this.isFailed = !!err || res.paymentStatus === 'cancelled'

		// Order place success dialog
		if (!this.isFailed && type === PAYTAB_PAYMENT_TYPE.CART) {
			this.dialogSuccess = true
		}

		// Reset payment
		this.setSettingsState({
			currentPayment: {
				type: null,
				tranRef: null,
			},
		})

		const routes = {
			[PAYTAB_PAYMENT_TYPE.CART]: '/orders',
			[PAYTAB_PAYMENT_TYPE.SUBSCRIPTION]: '/subscription',
		}

		this.countDown = 3
		this.countDownInterval = setInterval(() => {
			this.countDown -= 1
			if (this.countDown <= 0) {
				clearInterval(this.countDownInterval)
				this.$router.replace(routes[type] || '/')
			}
		}, 1000)
	},
	methods: {
		...mapActions('settings', ['setSettingsState']),
		...mapActions('orders', ['placeOrder', 'setOrderState']),
		...mapActions('auth', ['fetchProfile']),
	},
	beforeDestroy() {
		clearInterval(this.countDownInterval)
	},
}
</script>
